import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit and coba lagi dan lagi dong ayo dong.
        </p>
        <a href="https://youtube.com">
         belajar auto deploy
        </a>
      </header>
    </div>
  );
}

export default App;
